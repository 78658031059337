import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="torino1833">
<h1>L’attentato al re</h1>
<p>Nel 1833 Carlo Alberto di Savoia, re di Sardegna, adotta una politica più severa nei confronti dei patrioti e firma la condanna a morte di alcuni esponenti mazziniani appartenenti alla “Giovane Italia”.</p>
<p>Antonio Gallenga, a seguito di questi provvedimenti, decide di compiere un gesto dimostrativo che possa sconvolgere gli equilibri tra gli Stati italiani e magari dare il via a una rivolta su scala italiana: recarsi a Torino e uccidere il re di Sardegna.</p>
<ImgCentro nome="Torino.PNG" alt="Torino" didascalia="Torino"/>
<p>Nell’agosto di quello stesso anno (1833), provvisto di una raccomandazione di Luigi Amedeo Melegari si reca a Ginevra per incontrare Giuseppe Mazzini, dal quale ottiene del denaro, un passaporto e, a suo dire (la questione è controversa), il beneplacito per il compimento del regicidio.<br />
Giunto a Locarno, Antonio adotta, in omaggio alla famiglia parmigiana che aveva protetto la sua fuga, il nome fittizio di “Luigi Mariotti” (uno pseudonimo che in seguito utilizzerà durante la sua carriera di scrittore) e poi raggiunge Torino.</p>
<p>Qui, dopo essersi messo in contatto con i membri locali della Giovane Italia, riceve da loro sostegno materiale e morale per l’organizzazione dell’attentato.<br />
Tuttavia, a fine settembre, a pochi giorni di distanza dal giorno scelto, i carabinieri perquisiscono la camera di un componente del comitato residente nella stessa strada dove risiede Antonio.</p>
<ImgCentro nome="genova.PNG" alt="genova" didascalia="Genova"/>
<p>Nel timore che le autorità abbiano scoperto i loro piani, il comitato torinese intima ad Antonio di lasciare la città, e lui obbedendo raggiunge Genova e poi Pontremoli, in Lunigiana.<br />
A fine ottobre i mazziniani tentano invano di rintracciarlo sulla costa ligure, ma di Antonio non vi è traccia.</p>
<ImgCentro nome="pontremoli.PNG" alt="Pontremoli" didascalia="Pontremoli (MS)"/>
<h2>Curiosità</h2>
<ImgDestra nome="barbareschi.jpg" alt="Barbareschi" didascalia="Luca Barbareschi nei panni di Antonio Gallenga nel film ‘Noi credevamo’ (Mario Martone, 2010)"/>
<p>Antonio Gallenga non è certo ricordato per essere uno dei personaggi principali del Risorgimento italiano.<br />
Tuttavia, scorrendo la sua biografia, si evince come il Gallenga abbia conosciuto più di tanti altri suoi illustri contemporanei i luoghi e i protagonisti che hanno caratterizzato il XIX secolo italiano.<br />
Il ruolo del Gallenga non è sfuggito al regista Mario Martone, che nel suo film sui moti risorgimentali “Noi credevamo” (2010), ha affidato il ruolo di Antonio a Luca Barbareschi.</p>
<h2>Link per approfondire:</h2>
<p><A href="https://www.treccani.it/enciclopedia/carlo-alberto-re-di-sardegna_%28Dizionario-Biografico%29/">Carlo Alberto di Savoia</A></p>
<p><A href="https://www.treccani.it/enciclopedia/regno-di-sardegna_%28Enciclopedia-dei-ragazzi%29/">Regno di Sardegna</A></p>

	</PaginaTesto>
}